export const BG_PRIMARY = '#FFF';
export const BG_SECONDARY = 'rgba(123,99,75,1)';
export const BG_ALTERNATIVE = 'rgba(196,181,161,1)';

export const TEXT_PRIMARY = '#352023';
export const TEXT_SECONDARY = '#FFF';
export const TEXT_PRIMARY_LIGHT = '#FFF';

export const BTN_NAVIGATION_COLOR = '#352023';

// Opacity layer for left content (Desktop view)
export const BG_SECONDARY_TRANSPARENT = '';

export const HEADING_STYLES = {
  color: 'mainColorText',
  fontFamily: 'heading',
  fontSize: '26px',
  letterSpacing: '3px',
  textAlign: 'center',
  textTransform: 'uppercase',
  //paddingLeft: '28px',
};

export const LINK_STYLES = {
  color: 'bgAlternative',
  textDecoration: 'underline',
  fontWeight: 'bold',
  fontStyle: 'italic',
};

export const DEFAULT_BUTTON_PROPS = {};
export const BUTTON_PROPS = {};
